import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { ReactComponent as OpenEyeIcon } from "../../../assets/icons/open-eye.svg";
import { ReactComponent as CloseEyeIcon } from "../../../assets/icons/close-eye.svg";
import { parseCamelKey } from "../../../utils/formatersAndParsers";
import { ErrorField } from "../../common/errorField";

export default function PasswordField({
  field = {},
  disableFields = false,
  values = {},
  handleChange = () => {},
  errors = {},
}) {
  const [showpass, setshowpass] = useState(false);

  const eyetoggle = () => {
    setshowpass(!showpass);
  };

  return (
    <>
      <div className="position-relative">
        <div className=" d-flex align-items-center gap-3 w-100">
          <label htmlFor="password">{field.label || parseCamelKey(field.value)}</label>
        </div>
        <Button variant="" className="eye_btn" onClick={eyetoggle}>
          {showpass ? <OpenEyeIcon /> : <CloseEyeIcon />}
        </Button>
        <input
          type={showpass ? "text" : "password"}
          id="password"
          className="form-control"
          placeholder={field.placeholder || parseCamelKey(field.value)}
          disabled={disableFields}
          name={field.value}
          value={values[field.value]}
          onChange={handleChange}
        />
        <p className="small muted m-0">
          Use 8 or more characters with a mix of letters, numbers & symbols
        </p>
      </div>
      <ErrorField error={errors[field.value]} />
    </>
  );
}
