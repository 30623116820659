import React from "react";
import banner from "../../../assets/images/homeBanner.jpg";

export default function StoryCard({ index }) {
  return (
    <div className="homeStoryCard mx-2 p-4">
      <div className="pb-3 border-bottom border-secondary">
        <div className="d-flex align-items-center justify-content-start mb-2">
          <img
            src={banner}
            className="img-fluid object-fit-cover rounded me-2"
            style={{ width: 40, height: 40 }}
          />
          <h4 className="m-0 text-truncate">Hello there {index} </h4>
        </div>
        <p className="storyDesc m-0">
          {`Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas deserunt ducimus eligendi
        quibusdam ipsa expedita et officia iste architecto! Impedit dignissimos, vitae voluptates
        labore modi consectetur aliquid nam nulla quas.`}
        </p>
      </div>
    </div>
  );
}
