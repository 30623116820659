import React from "react";
import axios from "axios";
import { LoginSocialGoogle } from "reactjs-social-login";
import { errorMsg } from "../../../utils/toastMessage";

const SocialGoogleLogin = () => {
  const onLoginStart = () => {
    console.log("login started");
  };

  const handleLogin = async (event) => {
    const { provider, data } = event;

    console.log("data", provider, data);

    // backend code

    try {
      const tokenRequest = {
        code: data.code,
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        client_secret: process.env.REACT_APP_GOOGLE_ACCOUNT_SECRET,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        grant_type: "authorization_code",
      };

      const tokenResponse = await axios.post("https://oauth2.googleapis.com/token", tokenRequest);
      console.log("tokenResponse", tokenResponse);
      const userPropfileResponse = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse?.data?.access_token}`
      );

      console.log("userPropfileResponse", userPropfileResponse);

      const { email, name, picture } = userPropfileResponse?.data || {};

      const request = {
        loginType: "google",
        googleId: data?.code,
        email: email,
        name: name,
        avatar: picture,
      };

      //   dispatch(loginUser(request, callBack));
    } catch (error) {
      errorMsg(error?.message || error?.error || JSON.stringify(error));
      console.log("error", error);
    }
  };

  return (
    <>
      <LoginSocialGoogle
        client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
        redirect_uri={process.env.REACT_APP_REDIRECT_URI}
        scope="openid profile email"
        discoveryDocs="claims_supported"
        access_type="offline"
        onLoginStart={onLoginStart}
        onResolve={handleLogin}
        onReject={(err) => {
          console.log("err", err);
        }}
      >
        <div className={`googleLoginBtn`}>
          <button className={`primaryBtn w-100 rounded py-2 px-3`}>
            <span className="icn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="24"
                viewBox="0 0 23 24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M22.7111 12.2584C22.7111 11.4538 22.6389 10.6802 22.5048 9.9375H11.8184V14.3266H17.9249C17.6619 15.7449 16.8625 16.9466 15.6607 17.7512V20.5982H19.3278C21.4733 18.6228 22.7111 15.714 22.7111 12.2584Z"
                  fill="#4285F4"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.8185 23.347C14.8821 23.347 17.4505 22.331 19.3279 20.598L15.6609 17.7511C14.6448 18.4319 13.3451 18.8342 11.8185 18.8342C8.8632 18.8342 6.36178 16.8382 5.46952 14.1562H1.67871V17.0961C3.54575 20.8043 7.38298 23.347 11.8185 23.347Z"
                  fill="#34A853"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.46936 14.1558C5.24242 13.475 5.11349 12.7478 5.11349 12C5.11349 11.2521 5.24242 10.5249 5.46936 9.84411V6.9043H1.67855C0.910072 8.43609 0.47168 10.169 0.47168 12C0.47168 13.8309 0.910072 15.5638 1.67855 17.0956L5.46936 14.1558Z"
                  fill="#FBBC05"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.8185 5.16619C13.4844 5.16619 14.9801 5.73868 16.156 6.86303L19.4104 3.6086C17.4454 1.77767 14.8769 0.65332 11.8185 0.65332C7.38298 0.65332 3.54575 3.196 1.67871 6.90429L5.46952 9.8441C6.36178 7.16216 8.8632 5.16619 11.8185 5.16619Z"
                  fill="#EA4335"
                />
              </svg>
            </span>
            <span className="ms-2">Continue with google</span>
          </button>
        </div>
      </LoginSocialGoogle>
    </>
  );
};

export default SocialGoogleLogin;
