import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TransparentSelect from "../../Component/common/transparentSelect";
import { defaultPage, limitOptions, sortingOptions } from "../../utils/constants";
import { isArray } from "../../utils/formatersAndParsers";
import { handleApiRequest } from "../../services/handleApiRequest";
import { handleTransformValues } from "../../utils/formatersAndParsers";
import { getStoriesList } from "../../redux/stories/thunk";
import AskToShare from "../../Component/askToShare";
import MySelect from "../../Component/common/mySelect";
import MyPagination from "../../Component/common/pagination";
import StoryCard from "./components/storyCard";
import ReactionsListsPop from "../../Component/Modals/reactionsListPop";
import LoadIndicator from "../../Component/Loader";

export default function MyStories() {
  const { categories = [] } = useSelector((state) => state.common || {});
  const { myStoriesList = {}, loadingStoriesList = false } = useSelector(
    (state) => state.stories || {}
  );

  const [userAction, setUserAction] = useState(null);
  const [paginationDetails, setPaginationDetails] = useState({
    ...defaultPage,
    orderBy: sortingOptions[0],
  });
  const [filters, setFilters] = useState({});

  const handleFiltersChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleLimit = (selected) => {
    setPaginationDetails((prev) => ({ ...prev, limit: selected.value }));
  };

  const handleStories = async () => {
    const selectedFilters = await handleTransformValues(filters);

    const request = {
      ...paginationDetails,
      orderBy: paginationDetails.orderBy?.orderBy,
      ...selectedFilters,
      isPrivate: false,
      listType: "user",
      listFor: "user",
    };
    await handleApiRequest(getStoriesList, request);
  };

  const feelingFilterOption = (option) => {
    return (
      <div className="d-flex align-items-center">
        <span
          className="optionIcon me-2"
          dangerouslySetInnerHTML={{ __html: option.iconRegular }}
        />
        <span className="">{option.name}</span>
      </div>
    );
  };

  useEffect(() => {
    handleStories();
  }, [paginationDetails, filters]);

  return (
    <>
      <div className="w-100 overflow-auto">
        <div
          className={`${
            loadingStoriesList ? "" : "hide-loader"
          } d-flex align-items-center justify-content-center`}
        >
          <LoadIndicator />
        </div>
        <div className="storyFilters d-block d-sm-flex align-items-center justify-content-between p-3 px-lg-5">
          <TransparentSelect
            placeholder="Relevance"
            className="sortingSelect"
            options={sortingOptions}
            value={paginationDetails.orderBy}
            onChange={(selected) =>
              setPaginationDetails((prev) => ({
                ...prev,
                orderBy: selected,
                order: selected.order,
              }))
            }
          />
          <TransparentSelect
            placeholder="Filter by Feeling"
            className="storyFilterContainer"
            options={isArray(categories)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option._id}
            formatOptionLabel={feelingFilterOption}
            value={filters.category}
            onChange={(selected) => handleFiltersChange("category", selected)}
          />
        </div>

        <div className={`bg-secondary px-2 px-lg-3 pt-3`}>
          {isArray(myStoriesList.records).map((story, i) => (
            <StoryCard key={i} story={story} storyType="user" handleReactionsList={setUserAction} />
          ))}
          <div className="d-flex justify-content-between py-3">
            <div>
              <MySelect
                classNamePrefix={true ? "darkSelect" : "mySelect"}
                options={limitOptions}
                isSearchable={false}
                value={{ value: paginationDetails.limit, label: paginationDetails.limit }}
                onChange={handleLimit}
              />
            </div>
            <MyPagination
              paginationDetails={paginationDetails}
              setPaginationDetails={setPaginationDetails}
              totalCount={myStoriesList.totalCount}
              darkPagination={true}
            />
          </div>
        </div>

        <div className="curvatureContainer">
          <div>
            <div id="curved-corner-topright" />
          </div>
          <div>
            <div id="curved-corner-topleft" />
          </div>
        </div>

        <AskToShare />
      </div>
      {userAction?.action === "showReactionsList" && (
        <ReactionsListsPop userAction={userAction} setUserAction={setUserAction} />
      )}
    </>
  );
}
