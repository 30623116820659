import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import userMaleImage from "../assets/images/userImage.png";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import SubHeader from "../Component/Header/subHeader";
import * as Path from "../routes/routesPath";
import DeletePopup from "../Component/Modals/deletePop";
import { handleApiRequest } from "../services/handleApiRequest";
import { logoutUser } from "../redux/auth/thunk";
import { logout } from "../redux/auth/slice";
import { getMyAccountPagePath, getUserImage } from "../utils/helpers";

const userCenter = [
  { label: "My Account", path: getMyAccountPagePath() },
  { label: "My Stories", path: getMyAccountPagePath(Path.storiesRoute) },
  {
    label: "Saved Stories",
    path: getMyAccountPagePath(Path.bookmarkedStoriesRoute),
  },
  {
    label: "Private Directory",
    path: getMyAccountPagePath(Path.privateDirectoryRoute),
  },
  {
    label: "My queries",
    path: getMyAccountPagePath(Path.myQueriesRoute),
  },
  // { value: "replies", label: "Comments and Replies", path: "" },
];

export default function MyAccountTheme() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state.auth || {});
  const [userAction, setUserAction] = useState(null);

  const handleLogout = async () => {
    dispatch(logout());
    navigate(Path.homeRoute);
    await handleApiRequest(logoutUser, {}, false);
  };

  console.log("userProfile", userProfile);

  return (
    <div className="mainWrapper">
      <Header />
      <main className="mainBody">
        <section className="storiesListContainer">
          <div className="subHeaderContainer bg-primary pb-1">
            <SubHeader />
          </div>

          <div className="profileContainer border-bottom border-white d-flex ">
            <div className="profileOptions bg-primary">
              <div className="text-white text-center">
                <img
                  className="userImage img-fluid object-fit-cover rounded-circle mt-3 mb-1"
                  src={getUserImage(userProfile)}
                  alt={!userProfile ? "userImage" : userProfile?.name}
                  onError={(e) => {
                    e.target.src = getUserImage();
                  }}
                />
                <p className="m-0">{userProfile?.name}</p>
                <p className="muted small m-0" style={{ lineHeight: "12px" }}>
                  {userProfile?.email}
                </p>
              </div>
              <div className="profileItemsList text-white mt-3">
                <div className="mx-2">
                  <h5 className="mb-1">Your Center</h5>
                  <div className="rounded border border-secondary px-2">
                    {userCenter.map((option, i) => (
                      <p
                        key={option.path}
                        className={`mb-0 pt-2 pb-1 d-flex align-items-center justify-content-between ${
                          i <= userCenter.length - 2 ? "border-bottom border-secondary" : ""
                        }`}
                        onClick={() => navigate(option.path)}
                      >
                        <span>{option.label}</span>
                        <span>
                          <IoIosArrowForward />
                        </span>
                      </p>
                    ))}
                  </div>
                </div>
                <div className="mx-2 my-3">
                  <h5 className="mb-1">About Us</h5>
                  <div className="rounded border border-secondary px-2">
                    <p
                      className={`mb-0 pt-2 pb-1 d-flex align-items-center justify-content-between border-bottom border-secondary`}
                      onClick={() => navigate(Path.contactUsRoute)}
                    >
                      <span>Contact us</span>
                      <span>
                        <IoIosArrowForward />
                      </span>
                    </p>
                  </div>
                </div>
                <p
                  className="mx-2 mb-3 mt-4 pb-1 d-flex align-items-center justify-content-between border-bottom"
                  onClick={() => setUserAction({ action: "logout" })}
                >
                  <span>Logout</span>
                  <span>
                    <IoIosArrowForward />
                  </span>
                </p>
              </div>
            </div>
            <Outlet />
            {userAction?.action === "logout" && (
              <DeletePopup
                userAction={userAction}
                setUserAction={setUserAction}
                onSubmit={handleLogout}
                submitLabel={"Logout"}
              />
            )}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
