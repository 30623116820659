import React from "react";
import { Button, Modal } from "react-bootstrap";
import { PiWarningCircleBold } from "react-icons/pi";

const DeletePopup = ({ userAction, setUserAction, onSubmit, submitLabel }) => {
  const handleClosePop = () => {
    setUserAction(null);
  };

  const handleDeletePop = async () => {
    handleClosePop();
    await onSubmit();
  };

  return (
    <>
      <Modal
        show={!!userAction}
        onHide={handleClosePop}
        backdrop="static"
        keyboard={false}
        centered
        size="sm"
        className="delete-pop"
      >
        <Modal.Body>
          <div className="text-center py-3">
            <div className="icn mb-3">
              <PiWarningCircleBold className="text-warning" style={{ width: 100, height: 100 }} />
            </div>
            <h2 className="pop-head m-0 pb-2">Are you Sure?</h2>
            <div className="btn-wrap my-2 d-flex align-items-center justify-content-center mt-3">
              <Button
                onClick={handleClosePop}
                className="btn me-2 d-flex align-items-center justify-content-center bg-secondary border-0"
              >
                No Cancel!
              </Button>
              <Button
                onClick={handleDeletePop}
                className="d-flex align-items-center justify-content-center bg-danger border-0"
              >
                {submitLabel || "Yes, Delete it"}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeletePopup;
