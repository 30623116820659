import React, { useEffect, useState } from "react";
import TransparentSelect from "../../Component/common/transparentSelect";
import { contactUsReason, defaultPage, limitOptions, sortingOptions } from "../../utils/constants";
import { isArray, parseCamelKey } from "../../utils/formatersAndParsers";
import { handleApiRequest } from "../../services/handleApiRequest";
import { handleTransformValues } from "../../utils/formatersAndParsers";
import AskToShare from "../../Component/askToShare";
import MySelect from "../../Component/common/mySelect";
import MyPagination from "../../Component/common/pagination";
import ReactionsListsPop from "../../Component/Modals/reactionsListPop";
import { queriesList } from "../../redux/queries/thunk";

export default function MyQueries() {
  const [userAction, setUserAction] = useState(null);
  const [queries, setQueries] = useState({});
  const [paginationDetails, setPaginationDetails] = useState({
    ...defaultPage,
    orderBy: sortingOptions[0],
  });
  const [filters, setFilters] = useState({});

  const handleFiltersChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleLimit = (selected) => {
    setPaginationDetails((prev) => ({ ...prev, limit: selected.value }));
  };

  const handleQueries = async () => {
    const selectedFilters = await handleTransformValues(filters);

    const request = {
      ...paginationDetails,
      orderBy: paginationDetails.orderBy?.orderBy,
      //   ...selectedFilters,
    };
    const response = await handleApiRequest(queriesList, request);
    if (response.status) {
      setQueries(response.data || {});
    }
  };

  useEffect(() => {
    handleQueries();
  }, [paginationDetails, filters]);

  return (
    <>
      <div className="w-100 overflow-auto">
        <div className="storyFilters d-block d-sm-flex align-items-center justify-content-between p-3 px-lg-5">
          <TransparentSelect
            placeholder="Relevance"
            className="sortingSelect"
            options={sortingOptions}
            value={paginationDetails.orderBy}
            onChange={(selected) =>
              setPaginationDetails((prev) => ({
                ...prev,
                orderBy: selected,
                order: selected.order,
              }))
            }
          />
          <TransparentSelect
            placeholder="Filter by reason"
            className="w-230"
            options={isArray(contactUsReason)}
            value={filters.category}
            onChange={(selected) => handleFiltersChange("category", selected)}
          />
        </div>

        <div className={`bg-secondary px-2 px-lg-3 pt-3`}>
          {isArray(queries.records).map((query, i) => (
            <div className="pointer bg-secondary position-relative shadow mb-2 py-3 px-2 px-lg-3">
              <p
                className={`position-absolute end-0 top-0 m-2 rounded-pill px-2 text-white ${
                  query.status === "active"
                    ? "bg-primary-default"
                    : query.status === "closed"
                    ? "bg-danger"
                    : "bg-success"
                } `}
              >
                {parseCamelKey(query.status)}
              </p>
              <h5>{contactUsReason.find((item) => item.value === query.reason)?.label}</h5>
              <p>{query.comment}</p>
            </div>
          ))}
          <div className="d-flex justify-content-between py-3">
            <div>
              <MySelect
                classNamePrefix={true ? "darkSelect" : "mySelect"}
                options={limitOptions}
                isSearchable={false}
                value={{ value: paginationDetails.limit, label: paginationDetails.limit }}
                onChange={handleLimit}
              />
            </div>
            <MyPagination
              paginationDetails={paginationDetails}
              setPaginationDetails={setPaginationDetails}
              totalCount={queries.totalCount}
              darkPagination={true}
            />
          </div>
        </div>

        <div className="curvatureContainer">
          <div>
            <div id="curved-corner-topright" />
          </div>
          <div>
            <div id="curved-corner-topleft" />
          </div>
        </div>

        <AskToShare />
      </div>
      {userAction?.action === "showReactionsList" && (
        <ReactionsListsPop userAction={userAction} setUserAction={setUserAction} />
      )}
    </>
  );
}
