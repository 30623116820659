import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Alignment,
  Autosave,
  BlockQuote,
  Bold,
  Essentials,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  Italic,
  List,
  Paragraph,
  SelectAll,
  Table,
  TableToolbar,
} from "ckeditor5";
import successGif from "../../assets/images/successGif.gif";
import useAuth from "../../hooks/useAuth";
import RedirectPop from "../../Component/Modals/getquotesuccessmod";
import { handleApiRequest } from "../../services/handleApiRequest";
import { useSelector } from "react-redux";
import { isArray } from "../../utils/formatersAndParsers";
import { addStory, getStoryDetails, updateStory } from "../../redux/stories/thunk";
import { useFormik } from "formik";
import { postStorySchema } from "../../utils/validationSchemas";
import { ErrorField } from "../../Component/common/errorField";
import { successMsg } from "../../utils/toastMessage";
import { addStoryRoute } from "../../routes/routesPath";
import { useParams } from "react-router-dom";
import { handleLoginPop } from "../../utils/helpers";
import CkEditor from "../../Component/formComponent/components/ckEditor";

const defaultValues = {
  title: "",
  description: "",
  category: "",
  anonymousSharing: false,
  isPrivate: false,
};

export default function AddStory() {
  const { storyId } = useParams();
  const { isAuthenticated } = useAuth();
  const { categories = [] } = useSelector((state) => state.common || {});
  const [userAction, setUserAction] = useState({ action: "" });
  const [storyUploaded, setStoryUploaded] = useState(false);

  const handleStoryDetails = async () => {
    const response = await handleApiRequest(getStoryDetails, { storyId });
    if (response.status) {
      const { title, description, category, anonymousSharing, isPrivate, _id } = response.data;
      const oldStoryDetails = {
        title,
        description,
        anonymousSharing,
        isPrivate,
        category: category._id,
        storyId: _id,
      };
      setValues(oldStoryDetails);
    }
  };

  const handlePostStory = async (values, { resetForm, setSubmitting }) => {
    if (!isAuthenticated) return handleLoginPop(true);
    let response = {};
    if (storyId) {
      response = await handleApiRequest(updateStory, values);
    } else {
      response = await handleApiRequest(addStory, values);
    }
    if (response.status) {
      successMsg("Your story is saved");
      setStoryUploaded(true);
      resetForm();
    }
  };

  const { values, errors, isSubmitting, handleChange, handleSubmit, setFieldValue, setValues } =
    useFormik({
      validateOnChange: false,
      validateOnBlur: false,
      initialValues: defaultValues,
      validationSchema: postStorySchema,
      onSubmit: handlePostStory,
    });

  useEffect(() => {
    if (storyId) {
      handleStoryDetails();
    }
  }, [storyId]);

  return (
    <>
      {!storyUploaded ? (
        <>
          <section className="storiesListContainer">
            <div className="p-4 px-lg-0">
              <h4 className="my-3 text-center">How You're Feeling today</h4>
              <Form className="addStoryWrapper p-3 p-lg-4 px-lg-5" onSubmit={handleSubmit}>
                <div className="my-2">
                  <label className="theme-clr">Title</label>
                  <input
                    className="myInput form-control"
                    placeholder="Please enter a short title"
                    min={5}
                    max={150}
                    name="title"
                    value={values.title || ""}
                    onChange={handleChange}
                  />
                  <ErrorField error={errors.title} />
                </div>

                <div className="my-2">
                  <label className="theme-clr">Describe your Feeling</label>
                  <CkEditor
                    data={values.description || ""}
                    onChange={(data) => {
                      setFieldValue("description", data);
                    }}
                  />
                  <ErrorField error={errors.description} />
                </div>
                <div className="my-2">
                  <label>Select your Feeling</label>
                  <div className="feelingContainer d-flex align-items-center">
                    {isArray(categories).map((feeling) => (
                      <div
                        key={feeling.label}
                        className="pointer"
                        onClick={() => setFieldValue("category", feeling._id)}
                      >
                        {feeling._id === values.category ? (
                          <span
                            className=""
                            dangerouslySetInnerHTML={{ __html: feeling?.iconFilled }}
                          />
                        ) : (
                          <span
                            className=""
                            dangerouslySetInnerHTML={{ __html: feeling?.iconRegular }}
                          />
                        )}
                        <p className="text-medium mb-0 fw-bold">{feeling.name}</p>
                      </div>
                    ))}
                  </div>
                  <ErrorField error={errors.category} />
                </div>

                <div className="my-2 d-flex align-items-center">
                  <input
                    id="anonymousSharing"
                    className="checkbox pointer me-2"
                    type="checkbox"
                    name="anonymousSharing"
                    checked={values.anonymousSharing}
                    onChange={handleChange}
                  />
                  <label htmlFor="anonymousSharing" className="pointer">
                    Hide your real name
                  </label>
                </div>
                <div className="my-2 d-flex align-items-center">
                  <input
                    id="isPrivate"
                    className="checkbox pointer me-2"
                    type="checkbox"
                    name="isPrivate"
                    checked={values.isPrivate}
                    onChange={handleChange}
                  />
                  <label htmlFor="isPrivate" className="pointer">
                    Save to your private folder
                  </label>
                </div>
                <div className="d-flex justify-content-center">
                  {isSubmitting ? (
                    <>
                      <button type="submit" className="primaryBtn mt-2 px-5" disabled>
                        Submitting
                        <div className="growLoader spinner-grow ms-2" role="status"></div>
                      </button>
                    </>
                  ) : (
                    <button type="submit" className="primaryBtn mt-2 px-5">
                      Share your Feeling
                    </button>
                  )}
                </div>
              </Form>
            </div>
          </section>

          {userAction?.action === "unAuthorized" && (
            <RedirectPop userAction={userAction} setUserAction={setUserAction} />
          )}
        </>
      ) : (
        <>
          <section className="">
            <div className="d-flex flex-column align-items-center justify-content-center m-lg-5 m-3 shadow p-3">
              <h5 className="mt-4 fw-bold">Your post has been added.</h5>
              <h3 className="mb-3 text-center">Have a Nice Cheerful Day!!</h3>
              <img src={successGif} className="img-fluid" />
              <h5 className="text-center">We are always here to support you.</h5>
              <p className="text-center">Let us know what we can do for you...</p>
              <button className="primaryBtn rounded px-4 py-2 mb-3">Contact us</button>
            </div>
          </section>
        </>
      )}
    </>
  );
}
