import React, { useEffect, useState } from "react";
import { reactionsIcons } from "../../utils/constants";
import { BiLike } from "react-icons/bi";
import { handleApiRequest } from "../../services/handleApiRequest";
import { manageStoryReaction } from "../../redux/stories/thunk";
import useAuth from "../../hooks/useAuth";
import { handleLoginPop } from "../../utils/helpers";

export default function AddReaction({
  savedReaction,
  showReaction,
  setShowReaction,
  reactionsCount = 0,
  storyId = "",
  handleUpdateStory,
  position = "",
}) {
  const { isAuthenticated } = useAuth();
  const [myReaction, setMyReaction] = useState("");

  const handleReaction = async (e, reaction) => {
    e.stopPropagation();
    if (!isAuthenticated) return handleLoginPop(true);

    setShowReaction("");
    setMyReaction(reaction);
    handleUpdateStory("reactionsCountUpdate", myReaction);

    const request = { storyId, reaction };
    const response = await handleApiRequest(manageStoryReaction, request);
    if (!response.status) {
      handleUpdateStory("reactionsCountReverse", myReaction);
      setMyReaction("");
    }
  };

  useEffect(() => {
    if (!myReaction) {
      setMyReaction(savedReaction);
    }
  }, [storyId]);

  return (
    <>
      <p
        onClick={(e) => {
          e.stopPropagation();
          setShowReaction(storyId);
        }}
      >
        <span className="reactedIcon">{myReaction ? reactionsIcons[myReaction] : <BiLike />}</span>
        <span className="d-none d-lg-inline-block">Reacts ({reactionsCount || 0})</span>
      </p>

      {showReaction === storyId && (
        <div
          className="reactionOptionContainer d-flex align-items-center"
          style={position === "left" ? { right: 0, left: "unset" } : {}}
        >
          <span onClick={(e) => handleReaction(e, "like")}>{reactionsIcons.like}</span>
          <span onClick={(e) => handleReaction(e, "love")}>{reactionsIcons.love}</span>
          <span onClick={(e) => handleReaction(e, "laugh")}>{reactionsIcons.laugh}</span>
          <span onClick={(e) => handleReaction(e, "information")}>
            {reactionsIcons.information}
          </span>
          <span onClick={(e) => handleReaction(e, "support")}>{reactionsIcons.support}</span>
        </div>
      )}
    </>
  );
}
