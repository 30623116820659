import React from "react";
import { Routes, Route } from "react-router-dom";
import MainTheme from "../themes/main-theme";
import * as Path from "./routesPath";
import Pagenotfound from "../Pages/404page";
import Home from "../Pages/home";
import Stories from "../Pages/stories";
import CommunityGuide from "../Pages/community";
import StoryDetails from "../Pages/storiesDetails";
import AddStory from "../Pages/addStory";
import Signin from "../Pages/Auth/Signin";
import Signup from "../Pages/Auth/Signup";
import ResetPassowrd from "../Pages/Auth/reset-password";
import MyAccountTheme from "../themes/myAccount-theme";
import UpdateProfile from "../Pages/myAccount/profile";
import MyStories from "../Pages/myAccount/myStories";
import PrivateDirectory from "../Pages/myAccount/privateDirectory";
import BookmarkedStories from "../Pages/myAccount/bookmarkedStories";
import useAuth from "../hooks/useAuth";
import ContactUs from "../Pages/contactUs";
import MyQueries from "../Pages/myAccount/queries";

function MyRouts() {
  const { isAuthenticated } = useAuth();

  const getAbsoultePath = (path) => {
    return path.replace("/", "");
  };

  return (
    <>
      <Routes onUpdate={() => window.scrollTo(0, 0)}>
        {!isAuthenticated && (
          <>
            <Route path={Path.signupRoute} element={<Signup />} />
            <Route path={Path.signinRoute} element={<Signin />} />
            <Route path={Path.resetPasswordRoute} element={<ResetPassowrd />} />
          </>
        )}

        <Route onUpdate={() => window.scrollTo(0, 0)} path="/" element={<MainTheme className="" />}>
          <Route index element={<Home />} />
          <Route path={Path.communitiesRoute} element={<CommunityGuide />} />
          <Route path={Path.storiesRoute} element={<Stories />} />
          <Route path={`${Path.storyDetailsRoute}/:storyId`} element={<StoryDetails />} />
          <Route path={Path.addStoryRoute} element={<AddStory />} />
          <Route path={`${Path.editStoryRoute}/:storyId`} element={<AddStory />} />
          <Route path={Path.contactUsRoute} element={<ContactUs />} />
        </Route>

        {isAuthenticated && (
          <Route
            onUpdate={() => window.scrollTo(0, 0)}
            path={Path.myAccountRoute}
            element={<MyAccountTheme className="" />}
          >
            <Route path={getAbsoultePath(Path.profileRoute)} element={<UpdateProfile />} />
            <Route path={getAbsoultePath(Path.storiesRoute)} element={<MyStories />} />
            <Route
              path={getAbsoultePath(Path.bookmarkedStoriesRoute)}
              element={<BookmarkedStories />}
            />
            <Route
              path={getAbsoultePath(Path.privateDirectoryRoute)}
              element={<PrivateDirectory />}
            />
            <Route path={getAbsoultePath(Path.myQueriesRoute)} element={<MyQueries />} />
          </Route>
        )}

        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
}
export default MyRouts;
