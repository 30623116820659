import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { signinRoute } from "../../routes/routesPath";

export default function RedirectPop({ userAction, setUserAction }) {
  const navigate = useNavigate();

  const handleClose = () => {
    setUserAction(null);
  };

  const handleLogin = () => {
    navigate(signinRoute, { state: userAction.state });
    handleClose();
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      handleLogin();
    }, 2000);

    return () => clearTimeout(timeOut);
  }, [userAction]);

  return (
    <Modal
      show={userAction?.action === "unAuthorized"}
      onHide={handleClose}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal_uploadcv"
    >
      <Modal.Body>
        <div className="modal_uploadcv_inner position-relative">
          <div className="modal_uploadcv_confimation text-center">
            <h4 className="mb-4 mt-3">User not Logged In !!</h4>

            <p className="">
              You are not loged in.
              <br />
              Redirecting you to login
            </p>

            <button className="primaryBtn w-100" onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
