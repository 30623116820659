import React from "react";
import { Col, Row } from "react-bootstrap";
import { IoIosShareAlt } from "react-icons/io";
import storyImage from "../../assets/images/storyImage.png";

export default function AskToShare() {
  return (
    <div className="ourMission py-5 px-3 px-lg-5">
      <Row className="align-items-center">
        <Col md={6} className="">
          <div className="content">
            <h2 className="">Help People like you</h2>
            <p className="bannerDesc my-4">
              In an age where technology dominates our daily lives. We are dedicated to provide
              support and resources for those struggling with mental health issues, relationship
              problems, or other challenges by bringing people together. By sharing these platforms
              with more individuals, we can expand the reach of these communities and ensure that no
              one feels isolated or alone in their struggles.
              <br />
              <ul>
                <li>Share the community with the people in need.</li>
                <li>Donate us to reach more people like you.</li>
              </ul>
            </p>
          </div>
          <div className="d-flex align-items-center gap-10 mb-4">
            <button className="primaryBtn d-flex align-items-center rounded mx-2 px-3 py-1">
              Share <IoIosShareAlt className="ms-2" />
            </button>
            {/* <button className="primaryBtn d-flex align-items-center rounded">
              Donate <BiSolidDonateHeart className="ms-2" />
            </button> */}
          </div>
        </Col>
        <Col md={6} className="text-end">
          <img src={storyImage} className="img-fluid w-100" />
        </Col>
      </Row>
    </div>
  );
}
