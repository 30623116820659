import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import {
  addStoryRoute,
  communitiesRoute,
  homeRoute,
  signinRoute,
  storiesRoute,
} from "../../routes/routesPath";
import useAuth from "../../hooks/useAuth";
import { getMyAccountPagePath, getUserImage } from "../../utils/helpers";
import { useSelector } from "react-redux";

export default function Header() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { userProfile } = useSelector((state) => state.auth);

  return (
    <>
      <header className="headerContainer">
        <div className="header">
          <Link to={homeRoute} className="navbar-brand">
            <div className="d-flex align-items-center">
              <img className="mainLogo img-fluid" src={"/appLogo.png"} alt="logo" />
              {/* <div style={{ lineHeight: "15px" }}>
                <p className="headerLogoName m-0">Feelinghub</p>
                <p className="small muted m-0">Connect, Share, Empower</p>
              </div> */}
            </div>
          </Link>

          <div className="header-right">
            <div className="me-4 d-none d-md-block">
              <button
                className="textBtn headerLink"
                onClick={() => {
                  navigate(storiesRoute);
                }}
              >
                Stories
              </button>
              <button className="textBtn headerLink" onClick={() => navigate(communitiesRoute)}>
                Support
              </button>
            </div>
            <button className="primaryBtn rounded" onClick={() => navigate(addStoryRoute)}>
              Express your Feeling
            </button>
            {isAuthenticated ? (
              <img
                src={getUserImage(userProfile)}
                alt={userProfile.name}
                onError={(e) => {
                  e.target.src = getUserImage();
                }}
                className="headerIcon pointer img-fluid mx-2"
                onClick={() => navigate(getMyAccountPagePath())}
              />
            ) : (
              <MdAccountCircle
                className="headerIcon pointer mx-2"
                onClick={() => navigate(signinRoute)}
              />
            )}
          </div>
        </div>
      </header>
    </>
  );
}
