import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import AskToShare from "../Component/askToShare";
import { contactUsRoute } from "../routes/routesPath";

export default function MainTheme() {
  const { pathname } = useLocation();
  return (
    <div className="mainWrapper">
      <Header />
      <main className="mainBody">
        <Outlet />
        {pathname !== contactUsRoute && <AskToShare />}
      </main>
      <Footer />
    </div>
  );
}
