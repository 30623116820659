import userMaleImage from "../assets/images/userImage.png";
import userFemaleImage from "../assets/images/userImage-girl.png";
import store from "../redux/store";
import { manageLoginPop } from "../redux/common/slice";
import { myAccountRoute, profileRoute } from "../routes/routesPath";

export const getUserImage = (user) => {
  console.log("user");
  if (!user) return userMaleImage;

  if (user?.avatar?.url) {
    return user.avatar.url;
  }
  if (user?.gender === "female") {
    return userFemaleImage;
  }
  return userMaleImage;
};

export const handleAvatarError = (e) => {
  e.target.src = userMaleImage;
};

export const getMyAccountPagePath = (path) => {
  if (!path) return `${myAccountRoute}${profileRoute}`;
  return `${myAccountRoute}${path}`;
};

export const handleLoginPop = (action) => {
  const dispatch = store.dispatch;
  dispatch(manageLoginPop(action));
};
