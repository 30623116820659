import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdDeleteForever } from "react-icons/md";
import { BiLike } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { editStoryRoute, storyDetailsRoute } from "../../../routes/routesPath";
import { successMsg } from "../../../utils/toastMessage";
import { handleApiRequest } from "../../../services/handleApiRequest";
import { deleteStory } from "../../../redux/stories/thunk";
import DeletePopup from "../../../Component/Modals/deletePop";
import { updateStoriesList } from "../../../redux/stories/slice";
import StoryCardHeader from "../../../Component/storyCard/storyCardHeader";

export default function StoryCard({ story = {}, handleReactionsList = () => {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { myStoriesList = {} } = useSelector((state) => state.stories || {});
  const [userAction, setUserAction] = useState(null);

  const handleDelete = async () => {
    const response = await handleApiRequest(deleteStory, { storyId: story._id });
    if (response.status) {
      successMsg("Story deleted successfully");

      const index = myStoriesList.records?.findIndex((item) => item._id === story._id);
      const oldStories = [...(myStoriesList.records || [])];

      if (index >= 0) {
        oldStories.splice(index, 1);
      }

      dispatch(
        updateStoriesList({
          listFor: "user",
          records: oldStories,
          totalCount: myStoriesList.totalCount - 1,
        })
      );
    }
  };

  return (
    <>
      <div
        className="pointer bg-secondary shadow mb-2 py-3 px-2 px-lg-3"
        onClick={() => navigate(`${storyDetailsRoute}/${story._id}`)}
      >
        <StoryCardHeader story={story} />

        <h4 className="my-2 my-lg-3">{story.title}</h4>
        <div
          className="storyDesc-dark multiline-truncate"
          dangerouslySetInnerHTML={{ __html: story.description }}
        />

        <div className="storyAction d-flex align-items-center justify-content-between mt-3">
          <div className="d-flex align-items-center">
            <p className="">
              <span dangerouslySetInnerHTML={{ __html: story.category?.iconFilled }} />
              <span className="d-none d-lg-inline-block">{story.category?.name}</span>
            </p>
            <div className="position-relative">
              <p
                onClick={(e) => {
                  e.stopPropagation();
                  handleReactionsList({ action: "showReactionsList", storyId: story._id });
                }}
              >
                <span className="reactedIcon">
                  <BiLike />
                </span>
                <span className="d-none d-lg-inline-block me-1">Reacts</span>
                <span>({story?.reactionsCount || 0})</span>
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center gap-10">
            <button
              className="bg-secondary-light border-secondary-light d-flex align-items-start rounded px-3 py-1"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${editStoryRoute}/${story._id}`);
              }}
            >
              <span className="me-1 d-none d-lg-inline-block"> Edit</span>
              <FaEdit style={{ height: 20, width: 20 }} />
            </button>
            {story.status !== "deleted" && (
              <button
                className="bg-danger border border-danger rounded px-3 py-1 text-white d-flex align-items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  setUserAction({ action: "deletePost" });
                }}
              >
                <span className="d-none d-lg-inline-block">Delete</span>
                <MdDeleteForever style={{ height: 20, width: 20 }} />
              </button>
            )}
          </div>
        </div>
      </div>

      {userAction?.action === "deletePost" && (
        <DeletePopup
          userAction={userAction}
          setUserAction={setUserAction}
          onSubmit={handleDelete}
        />
      )}
    </>
  );
}
