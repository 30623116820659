import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import TransparentSelect from "../../Component/common/transparentSelect";
import { defaultPage, limitOptions, sortingOptions } from "../../utils/constants";
import SubHeader from "../../Component/Header/subHeader";
import { isArray } from "../../utils/formatersAndParsers";
import { handleApiRequest } from "../../services/handleApiRequest";
import { handleTransformValues } from "../../utils/formatersAndParsers";
import { getStoriesList } from "../../redux/stories/thunk";
import LoadIndicator from "../../Component/Loader";
import MySelect from "../../Component/common/mySelect";
import MyPagination from "../../Component/common/pagination";
import StoryCard from "../../Component/storyCard";

export default function Stories() {
  const { categories = [] } = useSelector((state) => state.common || {});
  const { storiesList = {}, loadingStoriesList = false } = useSelector(
    (state) => state.stories || {}
  );

  const [paginationDetails, setPaginationDetails] = useState({
    ...defaultPage,
    orderBy: sortingOptions[0],
  });
  const [filters, setFilters] = useState({});
  const [showReaction, setShowReaction] = useState("");

  const handleFiltersChange = (name, value) => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const handleLimit = (selected) => {
    setPaginationDetails((prev) => ({ ...prev, limit: selected.value }));
  };

  const handleStories = async () => {
    const selectedFilters = await handleTransformValues(filters);

    const request = {
      ...paginationDetails,
      orderBy: paginationDetails.orderBy?.orderBy,
      ...selectedFilters,
    };
    await handleApiRequest(getStoriesList, request);
  };

  const feelingFilterOption = (option) => {
    return (
      <div className="d-flex align-items-center">
        <span
          className="optionIcon me-2"
          dangerouslySetInnerHTML={{ __html: option.iconRegular }}
        />
        <span className="">{option.name}</span>
      </div>
    );
  };

  useEffect(() => {
    handleStories();
  }, [paginationDetails, filters]);

  return (
    <>
      <section className="storiesListContainer">
        <SubHeader />

        <Row className="m-0">
          <Col lg={8}>
            <div
              className={`${
                loadingStoriesList ? "" : "hide-loader"
              } d-flex align-items-center justify-content-center`}
            >
              <LoadIndicator />
            </div>
            <div className="storyFilters d-block d-sm-flex align-items-center justify-content-between p-3 px-lg-5">
              <TransparentSelect
                placeholder="Relevance"
                className="sortingSelect"
                options={sortingOptions}
                value={paginationDetails.orderBy}
                onChange={(selected) =>
                  setPaginationDetails((prev) => ({
                    ...prev,
                    orderBy: selected,
                    order: selected.order,
                  }))
                }
              />
              <TransparentSelect
                placeholder="Filter by Feeling"
                className="storyFilterContainer"
                options={isArray(categories)}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option._id}
                formatOptionLabel={feelingFilterOption}
                value={filters.category}
                onChange={(selected) => handleFiltersChange("category", selected)}
              />
            </div>

            <div className={` bg-primary text-white px-3 px-lg-5`}>
              {isArray(storiesList.records).map((story, i) => (
                <StoryCard
                  key={i}
                  story={story}
                  showReaction={showReaction}
                  setShowReaction={setShowReaction}
                />
              ))}
              <div className="d-flex justify-content-between py-3">
                <div>
                  <MySelect
                    classNamePrefix={"mySelect"}
                    options={limitOptions}
                    isSearchable={false}
                    value={{ value: paginationDetails.limit, label: paginationDetails.limit }}
                    onChange={(selected) => handleLimit(selected)}
                  />
                </div>
                <MyPagination
                  paginationDetails={paginationDetails}
                  setPaginationDetails={setPaginationDetails}
                  totalCount={storiesList.totalCount}
                  darkPagination={false}
                />
              </div>
            </div>

            <div className="curvatureContainer">
              <div>
                <div id="curved-corner-topright" />
              </div>
              <div>
                <div id="curved-corner-topleft" />
              </div>
            </div>
          </Col>
          <Col className="d-none d-lg-block col-lg-4">
            <p>google add</p>
          </Col>
        </Row>

        {/* <div className="curvatureContainer lowerCurvatureContainer">
          <div>
            <div id="curved-corner-bottomright" className="curvedCorner" />
          </div>
          <div>
            <div id="curved-corner-bottomleft" className="curvedCorner" />
          </div>
        </div> */}
      </section>
    </>
  );
}
