import React from "react";
import PhoneInput from "react-phone-input-2";
import { isArray, parseCamelKey } from "../../utils/formatersAndParsers";
import MySelect from "../../Component/common/mySelect";
import { ErrorField } from "../../Component/common/errorField";
import { handleFile } from "../../services/handleFile";
import { errorMsg } from "../../utils/toastMessage";
import PasswordField from "./components/password";
import CkEditor from "./components/ckEditor";

export default function MyForm({
  values = {},
  setFieldValue = () => {},
  errors = {},
  touched = false,
  formFields = [],
  RestForm = () => <></>,
  disableFields = false,
  handleChange = () => {},
  setSubmitting = () => {},
}) {
  const handleValueChange = (name, value) => {
    setFieldValue(name, value);
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      setSubmitting(true);
      const fileURL = await handleFile(file, false);
      if (!fileURL) return errorMsg("Error uploading file");

      const myFile = {
        url: isArray(fileURL)[0]?.url || "",
        name: isArray(fileURL)[0]?.filename,
      };
      setFieldValue(name, myFile);
      setSubmitting(false);
    }
  };

  return (
    <>
      {formFields?.map((field) =>
        field.type === "phone" ? (
          <div key={field.value} className="position-relative my-2">
            <label htmlFor="emailid">{field.lable || parseCamelKey(field.value)}</label>
            <PhoneInput
              inputClass="w-100 border"
              buttonClass="border"
              inputStyle={{ height: 38 }}
              country="in"
              placeholder={field.placeholder || "Enter phone number"}
              disabled={disableFields}
              value={values[field.value]}
              onChange={(value, data, e, formattedValue) => {
                handleValueChange(field.value, formattedValue);
              }}
            />
            <ErrorField touched={touched} error={errors[field.value]} />
          </div>
        ) : field.type === "password" ? (
          <div key={field.value} className="my-2">
            <PasswordField
              field={field}
              disableFields={disableFields}
              values={values}
              handleChange={handleChange}
              errors={errors}
            />
          </div>
        ) : field.type === "select" ? (
          <div key={field.value} className="position-relative my-2">
            <label htmlFor="">{field.label || parseCamelKey(field.value)}</label>
            <MySelect
              placeholder={`Select ${field.value}`}
              isDisabled={disableFields}
              options={field.options}
              value={values[field.value] || ""}
              onChange={(selected) => {
                handleValueChange(field.value, selected);
              }}
            />

            <ErrorField touched={touched} error={errors[field.value]} />
          </div>
        ) : field.type === "checkbox" ? (
          <div key={field.value} className="position-relative my-2">
            <input
              type="checkbox"
              id={field.value}
              className="checkbox me-1"
              name={field.value}
              checked={values[field.value]}
              onChange={(e) => handleValueChange(field.value, e.target.checked)}
              disabled={disableFields}
            />
            <label htmlFor={field.value}>{field.label || parseCamelKey(field.value)}</label>
          </div>
        ) : field.type === "file" ? (
          <div key={field.value} className="position-relative my-2">
            <label htmlFor="is_newsletter_subscribe">
              {field.label || parseCamelKey(field.value)}
            </label>
            <input
              type="file"
              id={field.value}
              className="form-control"
              name={field.value}
              onChange={handleFileChange}
            />
          </div>
        ) : ["text", "email", "number", "date"].includes(field.type) ? (
          <div key={field.value} className="position-relative my-2">
            <label htmlFor="name">{field.label || parseCamelKey(field.value)}</label>
            <input
              type={field.type || "text"}
              id={field.value}
              className="form-control"
              placeholder={field.placeholder || parseCamelKey(field.value)}
              disabled={disableFields}
              name={field.value}
              value={values[field.value]}
              onChange={handleChange}
            />
            <ErrorField touched={touched} error={errors[field.value]} />
          </div>
        ) : field.type === "textarea" ? (
          <div key={field.value} className="position-relative my-2">
            <label htmlFor="name">{field.label || parseCamelKey(field.value)}</label>
            <textarea
              className="form-control"
              placeholder={field.placeholder || parseCamelKey(field.value)}
              disabled={disableFields}
              name={field.value}
              value={values[field.value]}
              onChange={handleChange}
            />
            <ErrorField error={errors[field.value]} />
          </div>
        ) : field.type === "ckEditor" ? (
          <div key={field.value} className="position-relative my-2">
            <label htmlFor="name">{field.label || parseCamelKey(field.value)}</label>
            <CkEditor
              data={field.value}
              onChange={(data) => {
                handleValueChange(field.value, data);
              }}
            />
            <ErrorField error={errors[field.value]} />
          </div>
        ) : (
          ""
        )
      )}
      <RestForm />
    </>
  );
}
