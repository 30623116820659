import { HiHandThumbUp } from "react-icons/hi2";
import { FaHandHoldingHeart, FaHeart } from "react-icons/fa6";
import { TbBulbFilled } from "react-icons/tb";
import { FaLaughBeam } from "react-icons/fa";
import userMaleImage from "../assets/images/userImage.png";
import userFemaleImage from "../assets/images/userImage-girl.png";
import store from "../redux/store";
import { isArray } from "./formatersAndParsers";

export const passwordregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const maleAvatarHolder = userMaleImage;
export const femaleAvatarHolder = userFemaleImage;

export const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const storyReportReasons = [
  { value: "abusive", label: "Abusive" },
  { value: "askForMoney", label: "Ask for money" },
  { value: "violence", label: "Violence" },
  { value: "hateSpeech", label: "Hate speech" },
  { value: "falseInformation", label: "False information" },
];

export const sortingOptions = [
  { value: "relevance", label: "Relevance", orderBy: "_id", order: -1 },
  { value: "latestFirst", label: "Latest first", orderBy: "createdAt", order: -1 },
  { value: "oldestFirst", label: "Oldest first", orderBy: "createdAt", order: 1 },
];

export const defaultPage = {
  orderBy: "_id",
  order: -1,
  page: 1,
  limit: 20,
};

export const limitOptions = [
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 75, label: 75 },
  { value: 100, label: 100 },
];

export const getFeeling = (feeling) => {
  const { categories } = store.getState().common;
  for (let emotion of isArray(categories.items)) {
    if (emotion.value === feeling) {
      return emotion;
    }
  }
};

export const reactionsIcons = {
  like: <HiHandThumbUp style={{ color: "#2684ff" }} />,
  love: <FaHeart style={{ color: "red" }} />,
  laugh: <FaLaughBeam style={{ color: "orange" }} />,
  information: <TbBulbFilled style={{ color: "#ffe000", width: 30, height: 30 }} />,
  support: <FaHandHoldingHeart style={{ color: "#c90000" }} />,
};

export const contactUsReason = [
  { value: "suggestionOrFeedback", label: "Suggestion or Feedback" },
  { value: "problemOrComplaint", label: "Problem or Complaint" },
  {
    value: "reportIllegalOrInappropriateContent",
    label: "Report illegal or Inappropriate content",
  },
];
