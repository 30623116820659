import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addStory = createAsyncThunk("stories/addStory", async (data, Thunk) => {
  try {
    let response = await axios.post("story/add", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getStoriesList = createAsyncThunk("stories/getStoriesList", async (data, Thunk) => {
  try {
    let response = await axios.post("stories/list", data);
    return { ...(response?.data || {}), listFor: data.listFor || "main" };
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getStoryDetails = createAsyncThunk("stories/getStoryDetails", async (data, Thunk) => {
  try {
    let response = await axios.post("story/details", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const updateStory = createAsyncThunk("stories/updateStory", async (data, Thunk) => {
  try {
    let response = await axios.post("story/update", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const deleteStory = createAsyncThunk("stories/deleteStory", async (data, Thunk) => {
  try {
    let response = await axios.post("story/delete", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const reportStory = createAsyncThunk("stories/reportStory", async (data, Thunk) => {
  try {
    let response = await axios.post("story/report", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const manageStoryReaction = createAsyncThunk(
  "stories/manageStoryReaction",
  async (data, Thunk) => {
    try {
      let response = await axios.post("story/reaction/add", data);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const getStoryReactions = createAsyncThunk(
  "stories/getStoryReactions",
  async (data, Thunk) => {
    try {
      let response = await axios.post("story/reaction/list", data);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const addComment = createAsyncThunk("stories/addComment", async (data, Thunk) => {
  try {
    let response = await axios.post("story/comment/add", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getCommentList = createAsyncThunk("stories/getCommentList", async (data, Thunk) => {
  try {
    let response = await axios.post("story/comments/list", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const addCommentReply = createAsyncThunk("stories/addCommentReply", async (data, Thunk) => {
  try {
    let response = await axios.post("story/comment/reply/add", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getRepliesList = createAsyncThunk("stories/getRepliesList", async (data, Thunk) => {
  try {
    let response = await axios.post("story/comment/replies/list", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const manageCommentReaction = createAsyncThunk(
  "stories/manageCommentReaction",
  async (data, Thunk) => {
    try {
      let response = await axios.post("story/comment/reaction/add", data);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
