import React from "react";

export default function Footer() {
  return (
    <>
      <footer id="footerid" className="footer">
        <p className="m-0">© Copyright 2024 Feelinghub</p>
      </footer>
    </>
  );
}
