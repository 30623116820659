import React, { useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { handleApiRequest } from "../../services/handleApiRequest";
import { reportStory } from "../../redux/stories/thunk";
import MySelect from "../common/mySelect";
import { storyReportReasons } from "../../utils/constants";
import { useFormik } from "formik";
import { reportStorySchema } from "../../utils/validationSchemas";
import { ErrorField } from "../common/errorField";
import { successMsg } from "../../utils/toastMessage";

export default function ReportStoryPop({ userAction, setUserAction }) {
  const textareaRef = useRef(null);

  const handleClose = () => {
    setUserAction(null);
  };

  const handleReport = async () => {
    const request = { ...values, storyId: userAction.id };
    const response = await handleApiRequest(reportStory, request);

    if (response.status) {
      successMsg("Thanks for your feedback!!");
      handleClose();
    }
  };

  const { values, errors, handleChange, handleSubmit, setFieldValue, setValues } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: { reason: "", description: "" },
    validationSchema: reportStorySchema,
    onSubmit: handleReport,
  });

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "80px";
      textarea.style.height = `${textarea.scrollHeight + 2}px`;
    }
  }, [values.description]);

  return (
    <Modal
      show={!!userAction}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="md"
      className="reportStoryPop"
    >
      <Modal.Body>
        <div className="pt-3">
          <h6 className="text-center m-0">
            Let us know
            <br />
            What's wrong in this post?
          </h6>

          <div className="my-4">
            <div>
              <label>Reason</label>
              <MySelect
                options={storyReportReasons}
                value={
                  values.reason
                    ? storyReportReasons.find((item) => item.value === values.reason)
                    : ""
                }
                onChange={(selected) => {
                  setFieldValue("reason", selected?.value);
                }}
              />
              <ErrorField error={errors.reason} />
            </div>

            <div className="mt-2">
              <label>Description</label>
              <textarea
                ref={textareaRef}
                className="form-control"
                placeholder="Please describe what's wrong in this story"
                name="description"
                value={values.description}
                onChange={handleChange}
              />
              <ErrorField error={errors.description} />
            </div>
          </div>

          <div className="btn-wrap my-2 d-flex align-items-center justify-content-center mt-3 gap-10">
            <button onClick={handleClose} className="secondaryBtn rounded w-100">
              Back
            </button>
            <button
              onClick={async () => {
                handleSubmit();
              }}
              className="primaryBtn rounded w-100"
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
